import React, { memo, useEffect, Dispatch, ReducerAction, useState } from 'react';
import { useEditor, BubbleMenu } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import Underline from '@tiptap/extension-underline';
import Highlight from '@tiptap/extension-highlight';
import TextAlign from '@tiptap/extension-text-align';
import Placeholder from '@tiptap/extension-placeholder';
import { RichTextEditor, Link } from '@mantine/tiptap';

export interface IRichTextDescInputProps {
    field: string;
    dispatch: Dispatch<ReducerAction<any>>;
    state: any;
    type: string;
    label: string;
    disabled?: boolean;
    required?: boolean;
    description?: string;
    size?: string;
    icon?: JSX.Element;
    coreField?: boolean;
    titleStyleException?: boolean;
    debounce?: boolean;
    bubbleToolbar?: boolean;
    placeholder?: string;
  };

function RichTextDescInput({ field, dispatch, state, type, label, disabled, placeholder, required, description, size, icon, coreField = false, titleStyleException = false, debounce = false, bubbleToolbar = false }) {
    const [val, setVal] = useState(state || "");
  const editor = useEditor({
    extensions: [
      StarterKit,
      Underline,
      Highlight,
      TextAlign.configure({ types: ['heading', 'paragraph'] }),
      Placeholder.configure({ placeholder: `Enter ${placeholder}` || 'Enter here...' }),
      Link
    ],
    editable: !disabled,
    content: debounce ? val : state || "",
    onUpdate: ({ editor }) => {
      console.log('editor.isExpmpty',editor.isEmpty, editor.getHTML())
      const input = editor.getHTML();
      if (debounce) { setVal(() => input); }
        else if (!debounce) {
          dispatch({ type: 'REPLACE_GENERIC', payload: { input: editor.isEmpty ? "" : input, type, field } });
        }
    },

    onBlur: ({ event,editor }) => { if (debounce) { dispatch({ type: 'REPLACE_GENERIC', payload: { input: editor?.isEmpty ? "" : val, type, field } }); } }
  });
  useEffect(() => {
    // if (editor && state === "<p></p>") {
    //   editor.commands.setContent(state);
    // }
    if(editor) {
      if(state === "<p></p>") { editor.commands.setContent(state); }
      else if(editor.isEmpty && state?.length) { editor.commands.setContent(state); }
    }
  }, [state, editor]);

  const labelStyle = { display: 'inline-block', cursor: 'default', lineHeight: '1.55', fontSize: coreField ? '0.7em' : '0.8525em',  fontWeight: coreField ? 500 : 600, color: '#585858', letterSpacing: '0.01071em', fontFamily: '-apple-system,BlinkMacSystemFont,Segoe UI,Roboto,Helvetica,Arial,sans-serif,Apple Color Emoji,Segoe UI Emoji' }

  return (
    <>
    {bubbleToolbar ? <div style={labelStyle}>{label} {required ? <span style={{color: 'red'}}>*</span> : null}</div> : null}
    <RichTextEditor editor={editor} sx={{ width: '100%', wordBreak: 'break-word', marginTop: coreField ? '-4px' : '0.5em', marginBottom: coreField ? 0 : '0.5em' }}  >
      {disabled ? null :
      bubbleToolbar ? 
      editor && (
        <BubbleMenu editor={editor}>
          <RichTextEditor.ControlsGroup sx={{display: "flex", justifyContent: 'space-between',  width: '100%'}}>
          <div style={{ display: "flex", justifyContent: 'space-between',  width: '100%', flexWrap: 'wrap'}}>
            <div style={{ display: "flex" }}>
              <RichTextEditor.Bold />
              <RichTextEditor.Italic />
              <RichTextEditor.Underline />
              <RichTextEditor.Strikethrough />
              <RichTextEditor.Highlight />
              <RichTextEditor.ClearFormatting />
            </div>

            <div style={{ display: "flex" }}>
              <RichTextEditor.H1 />
              <RichTextEditor.H2 />
              <RichTextEditor.H3 />
              <RichTextEditor.H4 />
              <RichTextEditor.H5 />
              <RichTextEditor.H6 />
            </div>

            <div style={{ display: "flex" }}>
              <RichTextEditor.BulletList />
              <RichTextEditor.OrderedList />
              <RichTextEditor.Hr />
            </div>

            <div style={{ display: "flex" }}>
              <RichTextEditor.AlignLeft />
              <RichTextEditor.AlignCenter />
              <RichTextEditor.AlignJustify />
              <RichTextEditor.AlignRight />
            </div>
          </div>
          </RichTextEditor.ControlsGroup>
      </BubbleMenu>
      ) :
      <RichTextEditor.Toolbar sticky style={{ borderRadius: 0}} sx={{padding: '0.5em'}}>
          <RichTextEditor.ControlsGroup sx={{display: "flex", justifyContent: 'space-between',  width: '100%'}}>

            <div style={{ display: "flex", justifyContent: 'space-between',  width: '100%', flexWrap: 'wrap'}}>
              <div style={{ display: "flex" }}>
                <RichTextEditor.Bold />
                <RichTextEditor.Italic />
                <RichTextEditor.Underline />
                <RichTextEditor.Strikethrough />
                <RichTextEditor.Highlight />
                <RichTextEditor.ClearFormatting />
              </div>

              <div style={{ display: "flex" }}>
                <RichTextEditor.H1 />
                <RichTextEditor.H2 />
                <RichTextEditor.H3 />
                <RichTextEditor.H4 />
                <RichTextEditor.H5 />
                <RichTextEditor.H6 />
              </div>

              <div style={{ display: "flex" }}>
                <RichTextEditor.BulletList />
                <RichTextEditor.OrderedList />
                <RichTextEditor.Hr />
              </div>

              <div style={{ display: "flex" }}>
                <RichTextEditor.AlignLeft />
                <RichTextEditor.AlignCenter />
                <RichTextEditor.AlignJustify />
                <RichTextEditor.AlignRight />
              </div>
            </div>
          </RichTextEditor.ControlsGroup>
      </RichTextEditor.Toolbar>}
      <RichTextEditor.Content sx={{ fontSize: '0.8em', borderRadius: 0 }} />
    </RichTextEditor>
    </>
  );
}

export default memo(RichTextDescInput)
