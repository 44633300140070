import React, { useCallback, MutableRefObject } from 'react';
import { Button, Tooltip, Tabs } from '@mantine/core';
import { IconRefresh } from '@tabler/icons-react';
import { useHistory } from 'react-router';
import { observer } from 'mobx-react-lite';
import { useStore } from '../../models/ProvideModel';
import useStyles from './PlanningModuleStyles';
import { MRT_TableInstance, createRow } from 'mantine-react-table';
import { UnitActivityDetails } from './gridColumns';
import { getEntityDropdownType } from '../../models/enums';
/* import { processMantineRowToUA } from '../../models/Planning'; */

/* interface ISpecialFilterButton {
 *   push(val: any): void;
 *   location: any;
 *   id: string;
 *   label: string;
 *   search: string;
 *   isActive: boolean;
 * };
 *
 * const SpecialFilterButton = memo(({ push, location, id, label, search, isActive, }: ISpecialFilterButton) => {
 *   const onClick = () => {
 *     const loc = new URLSearchParams(search);
 *     if (isActive) {
 *       loc.delete(id);
 *     }
 *     else {
 *       loc.append(id, "true")
 *     }
 *     push({ ...location, search: loc.toString() });
 *   };
 *   return (
 *     // <Button
 *     //   className={isActive ? classes.sFDisabled : classes.sFApplied}
 *     //   onClick={onClick}
 *     // >
 *     //   {label}
 *     // </Button>
 *     <Checkbox
 *       label={label}
 *       onChange={onClick}
 *       checked={isActive}
 *       radius={'xs'}
 *       sx={{ ["& .mantine-Checkbox-label"]: { cursor: 'pointer', fontSize: '0.8525em', color: isActive ? "#005eff" : "#585858", '&:hover': { color: "#005eff" } }, ["& .mantine-Checkbox-input"]: { cursor: 'pointer' } }}
 *     />
 *   );
 * }); */

const PlanningModuleToolbar = ({ table, searchParams }: { table: MRT_TableInstance<UnitActivityDetails>; searchParams: MutableRefObject<URLSearchParams> }) => {
  const classes = useStyles();
  const store = useStore();
  const { push } = useHistory();
  /* const { search } = useLocation(); */

  /* const removeAppliedSF = (filterID, isActive) => {
   *   const loc = new URLSearchParams(search);
   *   if (isActive) {
   *     loc.delete(filterID);
   *   }
   *   else {
   *     loc.append(filterID, "true")
   *   }
   *   push({ ...location, search: loc.toString() });
   * } */
  const createNewRow = useCallback(() => {
    // You might need to revert to url->MST pattern
    table.setPageIndex(0);
    table.refs.tableContainerRef.current.scroll(0, 0);
    const row = createRow(table);
    table.setCreatingRow(row);
    store.planning.setMode('create', { id: row.id });
    if (!table.getState().columnVisibility['mrt-row-actions']) {
      table.setColumnVisibility({ 'mrt-row-actions': true });
    }
  }, []);

  const createNewUnit = useCallback(() => {
    store.planning.setCreateUnitPopup(true);
  }, []);
  const onViewChange = useCallback((val: string) => {
    store.planning.setCurrentView(val);
    searchParams.current.set("currentView", val);
    push(`${store.params.path}?${searchParams.current}`);
  }, []);

  return (
    <div style={{ display: 'flex', gap: '8px', flexGrow: 9, flexDirection: "column" }} >
      <Tabs
        variant="default"
        onTabChange={onViewChange}
        value={store.planning.currentView}
        style={{ display: 'flex', flexDirection: 'column', height: '100%', width: "100%", paddingTop: 20 }}
        styles={{
          root: { display: "flex", flexDirection: "column", height: "100%", width: "100%", flexBasis: "auto" },
          panel: { display: "flex", flexBasis: "auto", height: "100%", overflow: "hidden" }

        }}
      >
        <Tabs.List>
          <Tabs.Tab value="units">
            Units
          </Tabs.Tab>
          {!store.planning.hideUnitActivitiesFlag ?
            <Tabs.Tab value="unitActivities">
              Unit Activities
            </Tabs.Tab>
            : null
          }
        </Tabs.List>
      </Tabs>
      <div style={{ display: 'flex', justifyContent: 'space-between', flexGrow: 1, marginRight: '1em' }}>
        <div style={{ display: 'flex', flexGrow: 2 }}>
          <span style={{
            fontSize: "1.7em",
            display: "flex",
            justifyContent: "flex-start",
            fontWeight: 700,
            color: "#2F3E6C",
            // flexGrow: 2,
          }}>Planning Module &nbsp; <span style={{ fontSize: '0.7em', color: '#C9C9C9', alignSelf: 'center' }}>{`(${store.planning.filteredRowsCount})`}</span></span>

          <Tooltip label={store.planning.currentView === "units" ? "Refresh Units" : "Refresh Unit-Activities"}>
            <IconRefresh className={classes.refreshTasks} onClick={() => {
              if (store.planning.currentView === "units") {
                store.planning.getDropdownValue("units", getEntityDropdownType("units"));
              }
              else if (store.planning.currentView === "unitActivities")
                store.planning.getUnitActivities()
            }} />
          </Tooltip>

          {(store.planning.canCreate) ?
            <>
              <Button
                className={(store.planning.loading) ? classes.applyDisabled : classes.newRequestButton}
                disabled={store.planning.loading || !!table.getState().creatingRow}
                style={{ borderRadius: 0 }}
                onClick={createNewRow}
              >New Unit{store.planning.currentView === "units" ? "" : " Activity"}</Button>
              {store.planning.currentView !== "units" ?
                <Button
                  className={(store.planning.loading) ? classes.applyDisabled : classes.newRequestButton}
                  disabled={store.planning.loading || !!table.getState().creatingRow}
                  style={{ borderRadius: 0 }}
                  onClick={createNewUnit}
                >New Unit</Button>
                : null
              }
            </>
            : null}
        </div>
      </div>
    </div >
  );
};

export default observer(PlanningModuleToolbar);
