import React, { useEffect, useState, useMemo } from "react";
import {
  AppBar,
  Toolbar,
  Backdrop,
} from "@material-ui/core";
import styles from "./HeaderStyles";
import { useStore } from "../../models/ProvideModel";
import { useObserver } from "mobx-react-lite";
import projectPlaceholder from "../../assets/images/projectSelection/Project_Placeholder_Image.svg";
import { useHistory, useLocation, useParams } from "react-router";
import fbtLogoIcon from "../../assets/images/FBTLogo.svg";
import fbtImage from "../../assets/images/FBTImage.svg";
import SwitchTowersDD from "../../components/switchTowersDD/SwitchTowersDD";
import menuIcon from '../../assets/images/Menu.svg';
import { getReportName } from '../../utils/utils';
import client from "../../utils/apolloClient";
import LoadingSpinner from "../../components/loadingSkelaton/LoadingSpinner";
import useMenuStyles from "../../components/menuNavigation/menuPanel/MenuPanelStyles";
import { ModalProps, useModal, Modal, ModalBody, ModalContent, ModalOverlay } from "../../components/modal";
import NavigationPage from "../../pages/menuNavigationPage/NavigationPage";
import CloseDefault from '../../assets/images/menuNavigation/Close Default.svg'
import CloseDisable from '../../assets/images/menuNavigation/Arrow-Circle-Disabled.svg'
import { GOT } from '../../models/enums';
import NotificationBellIcon from '../notification/NotificationIcon';
/* import NotificationCenter from '../notification/NovuNotificationCenter'; */


const Header = () => {
  const store = useStore();
  /* const [open, setOpen] = useState(true); */
  const { push, goBack } = useHistory();
  const location = useLocation();
  const params = useParams<any>();
  const { search, pathname } = useMemo(() => location, [
    location.search,
    location.pathname,
  ]);
  const searchParam = useMemo(() => new URLSearchParams(search), [search]);
  const { spaceType, phase, screen, graphType, tower, section } = store.params;
  const newSection = (section === "unit-act-info") ? "unitAct" : section === "checklist-reports" ? "checklistReports" : section;
  const newScreen = (screen === "tasks-initiated") ? "tasksInitiated" : (screen === "tasks-assigned") ? "tasksAssigned" : screen;
  const styleProps = { backgroundColor: "", color: "white", width: (screen === "powerBI") ? "100%" : "80%" };
  const classes = styles(styleProps);
  const menuClasses = useMenuStyles();
  // TODO: Account for Baratheon types.
  const unitTypes: any = store.params.screen === "landing" && (store.params.spaceType === "tca" || store.params.spaceType === "eca" || store.params.spaceType === "basement") && (Array.from(store.currentDashboard?.graphs?.values() || []));
  const [hover, setHover] = useState<boolean>(false);

  const [modalState, setModalState] = React.useState(false);

  /* const onModalClose = () => {
   *   setModalState(!modalState);
   * } */

  const arg: ModalProps = {
    isOpen: modalState,
    closeOnOverlayClick: true
  }

  const { isOpen } = useModal(arg);

  const { isLg, isXs } = store.responsiveUtils.currentViewport;
  const hadlemenu = () => {
    searchParam.delete("popup_menu");
    searchParam.append("popup_menu", "true");
    push({ ...location, search: searchParam.toString() });
    setModalState(!modalState);
  }

  const onCloseClick = () => {
    /* searchParam.delete("popup_menu"); */
    /* searchParam.append("popup_menu", "false"); */
    /* replace({ ...location, search: searchParam.toString() }); */
    setModalState(!modalState);
    goBack();
  }

  useEffect(() => {
    setHover(false)
    if (location.search.match('popup_menu=true')) {
      setModalState(true);
    } else {
      setModalState(false);
    }
  }, [location.search]);

  // this is for sake of temporary purpose for 1st release
  // starting
  useEffect(() => {
    if (!client.client) {
      client();
    }
  }, [client]);

  // ending
  return useObserver(() => (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          {/* <Desktop> */}
          {(store.params.screen !== "powerBI") &&
            <div className={store.responsiveUtils.currentViewport.isLg ? store.filters.infopanelOpen ? classes.InfopanelheaderLeft : classes.headerLeft : classes.headerLeftSm}>
              <div onClick={hadlemenu} className={classes.left}>
                <img src={menuIcon} alt="menu" />
                {store.responsiveUtils.currentViewport.isLg && !store.filters.infopanelOpen && <div className={classes.propertyName}>{store.projectInfo.currentProject.description}</div>}
              </div>
            </div>
          }
          {/* </Desktop> */}
          <div
            className={(isLg) ? store.filters.infopanelOpen ? classes.infopanelheaderRight : classes.headerRight : classes.headerRightSm}
          >
            <div onClick={() => store.params.screen === "powerBI" && hadlemenu()} className={store.params.screen === "powerBI" ? classes.left : ""}
              style={{ display: "flex", alignItems: "center", clear: "both", overflow: store.params.screen === "powerBI" ? "unset" : "hidden" }}>
              {(store.params.screen === "powerBI") &&
                <div>
                  <img src={menuIcon} alt="menu" height="18px" width="18px" style={{ marginRight: "13px" }} />
                </div>
              }
              {store.params.screen === "landing" ? null :
                <div className={classes.backPageButton}>
                  <div onClick={goBack} className={classes.changeImage}></div>
                  <div className={classes.verticalDivider}></div>
                </div>}
              {/* {['brickGraph', 'barChart', 'table'].includes(graphType) && window.history.state !== null && ((store.currentTower && store.currentTower.hasData) ||
                  (graphType === "table" && phase === "finishing") || (['tower'].includes(spaceType) && ['tables'].includes(store.params.section))) ?
                  <div className={classes.backPageButton}>
                  <div onClick={goBack} className={classes.changeImage}></div>
                  <div className={classes.verticalDivider}></div>
                  </div>
                  : null} */}
              <div className={classes.menuClick}>
                <img
                  className={
                    store.projectInfo.projects[0].builder_image
                      ? classes.homeButton
                      : classes.homeButtonWOimg
                  }
                  onError={function defaultImage(img) {
                    img.currentTarget.onerror = null;
                    img.currentTarget.src = projectPlaceholder
                  }}
                  src={
                    store.projectInfo.projects[0].builder_image
                      ? store.projectInfo.projects[0].builder_image
                      : projectPlaceholder
                  }
                  height="45px"
                  alt="Builder"
                />
                {store.loading ? (
                  <Backdrop className={classes.backdrop} open={store.loading}>
                    <LoadingSpinner />
                  </Backdrop>
                ) : (
                  {
                    landing_dashboard: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        <span className={classes.ellipsisText}>{store.params.getSpaceTypeName(store.params.spaceType)} {store.params.getPhaseName(store.params.phase)} {"Overview"}</span>
                      </div>
                    ),
                    fullscreen_dashboard: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        <span className={classes.ellipsisText}>{store.params.getSpaceTypeName(store.params.spaceType)} {store.params.getPhaseName(store.params.phase)} {"Details"}</span>
                        {store.params.graphType.toUpperCase() !== GOT.BARATHEON &&
                          <span className={classes.ellipsisText} style={{ marginLeft: "4px" }}> - {getReportName(store.params.graphType)}</span>}
                      </div>
                    ),
                    fullscreen_unitAct: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        <span className={classes.ellipsisText}>{store.params.tower && store.towers.length ? store.towers.find(({ id }) => id === store.params.tower)?.name?.toString() : null} {store.params.getPhaseName(store.params.phase) || null} </span>
                        <div className={classes.ellipse}></div>
                        <span className={classes.ellipsisText}>{"List of activities & units"} </span>
                      </div>
                    ),
                    misc_legacy: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        <span className={classes.ellipsisText}>
                          {"Activity Steps "}
                        </span>
                      </div>
                    ),
                    landing_unitAct: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        <span className={classes.ellipsisText}>{"All Towers"} {store.params.getPhaseName(store.params.phase) || null}</span>
                        <div className={classes.ellipse}></div>
                        <span className={classes.ellipsisText}>{"List of activities & units"}</span>
                      </div>
                    ),
                    fullscreen_tables: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        <span className={classes.ellipsisText}>
                          {"List of Units"}
                        </span>
                      </div>
                    ),
                    landing_snags: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        {(phase !== "none") ?
                          <><span className={classes.ellipsisText}>{store.params.getSpaceTypeName(store.params.spaceType)} {store.params.getPhaseName(store.params.phase)}</span>
                          </> : "All Snags"}
                        {(phase !== "none") ?
                          <><div className={classes.ellipse}></div>
                            <span className={classes.ellipsisText}>SNAGS LIST</span></> : ""}
                      </div>
                    ),
                    landing_checklistReports: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        Checklist Reports
                        {(phase !== "none") ?
                          <><div className={classes.ellipse}></div>
                            <span className={classes.ellipsisText}>{store.params.getPhaseName(store.params.phase)}</span></> : ""}
                      </div>
                    ),
                    fullscreen_snags: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        <span className={classes.ellipsisText}>{store.params.getSpaceTypeName(store.params.spaceType)} {store.params.getPhaseName(store.params.phase)}</span>
                        <div className={classes.ellipse}></div>
                        <span className={classes.ellipsisText}>EXPORT SNAGS</span>
                      </div>
                    ),
                    landing_tasks: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        {(phase !== "none") ?
                          <><span className={classes.ellipsisText}>{store.params.getSpaceTypeName(store.params.spaceType)} {store.params.getPhaseName(store.params.phase)}</span>
                          </> : "All Tasks"}
                        <div className={classes.ellipse}></div>
                        <span className={classes.ellipsisText}>EXPORT TASKS</span>
                      </div>
                    ),
                    fullscreen_tasks: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        <span className={classes.ellipsisText}>{store.params.getSpaceTypeName(store.params.spaceType)} {store.params.getPhaseName(store.params.phase)}</span>
                        <div className={classes.ellipse}></div>
                        <span className={classes.ellipsisText}>EXPORT TASKS</span>
                      </div>
                    ),
                    powerBI_powerbi: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        <span className={classes.ellipsisText}>{store.projectInfo.currentProject.name}</span>
                        <div className={classes.ellipse}></div>
                        <span className={classes.ellipsisText}>{"Analytics"}</span>
                      </div>
                    ),
                    deafult: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        <span className={classes.ellipsisText}>{store.projectInfo.currentProject.name}</span>
                        <div className={classes.ellipse}></div>
                        <span className={classes.ellipsisText}>{store.params.getPhaseName(store.params.phase)}</span>
                      </div>
                    ),
                    fullscreen_legacy: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        <span className={classes.ellipsisText}>{store.params.getSpaceTypeName(store.params.spaceType)} {store.params.getPhaseName(store.params.phase)} {"Details"} - {getReportName(graphType)}</span>
                      </div>
                    ),
                    "landing_form-data": (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        Form Data Export
                      </div>
                    ),
                    "landing_form-data-write": (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        Edit Form Data
                      </div>
                    ),
                    tasksInitiated_materialModule: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        {(phase !== "none") ?
                          <><span className={classes.ellipsisText}>{spaceType} {phase}</span>
                          </> : "Material Handling Tool"}
                        <div className={classes.ellipse}></div>
                        <span className={classes.ellipsisText}>Raised By Me</span>
                      </div>
                    ),
                    tasksAssigned_materialModule: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        {(phase !== "none") ?
                          <><span className={classes.ellipsisText}>{spaceType} {phase}</span>
                          </> : "Material Handling Tool"}
                        <div className={classes.ellipse}></div>
                        <span className={classes.ellipsisText}>Assigned To Me</span>
                      </div>
                    ),
                    landing_requests: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        {(phase !== "none") ?
                          <><span className={classes.ellipsisText}>{spaceType} {phase}</span>
                          </> : store.ticket.moduleTitle}
                      </div>
                    ),
                    "landing_admin-tools": (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        Falconbrick Admin Tools
                      </div>
                    ),
                    landing_planning: (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        <span>Planning</span>
                        <div className={classes.ellipse}></div>
                        <span style={{ textTransform: 'uppercase' }}>{store.planning.currentView}</span>
                      </div>
                    ),
                    "landing_fbt-reports": (
                      <div className={isLg ? classes.header : classes.headerSm}>
                        FalconBrick Reports
                      </div>
                    ),
                  }[newScreen + '_' + newSection]
                )}
              </div>
            </div>
            <div className={newScreen === "unitInfo" && graphType === "table" ? null : store.responsiveUtils.currentViewport.isLg ? classes.rightHeaderDiv : classes.rightHeaderDivsm}>
              {['brickGraph', 'barChart', 'table', 'baratheon'].includes(graphType) && store.currentTower || store.currentTower?.hasData ?
                <div> {['unit-act-info'].includes(store.params.section) || ['misc'].includes(store.params.screen) || ['tca'].includes(store.params.spaceType) ? null : <SwitchTowersDD />}</div> : newScreen === "unitInfo" ? null :
                  <div className={classes.blockVilla}>
                    {(newScreen === "landing" && (graphType === "brickGraph" || graphType === "baratheon")) ?
                      store.params.spaceType === "tca" || store.params.spaceType === "eca" || store.params.spaceType === "basement" ? <div className={classes.TabDetails}>
                        {(store.currentDashboard?.towers?.length > 0) && <><div className={classes.TabName}>{store?.currentDashboard?.tab?.name}:</div>
                          <div className={classes.TabBlockdetails}>
                            <div>{store.currentDashboard?.towers?.length} Blocks</div>
                            {store.currentDashboard?.tab?.graphType.toUpperCase() !== GOT.BARATHEON && <><div className={classes.miniverticalDivider}></div>
                              <div>{unitTypes[0]?.columns.length} Units Types</div></>}
                          </div></>}
                      </div> : <div>{store.currentDashboard?.towers?.length} Blocks</div> : null}
                    {/* <div className={classes.verticalDivider}></div> */}
                    {/* <div>06 Villas</div> */}
                  </div>
              }
              <div style={{ display: 'flex', flexGrow: 1, justifyContent: "center" }}>
                <div className={classes.notification} style={{ right: '-1%' }}>
                  <NotificationBellIcon />
                </div>
                {/* <div className={classes.notification} style={{ right: '-5%' }}>
                    <NotificationCenter />
                    </div> */}
              </div>
              <img
                src={isXs ? fbtImage : fbtLogoIcon}
                width={isXs ? 39 : 106}
                height={isXs ? 24 : 48}
                alt="FBTLogo"
              />
            </div>
          </div>
        </Toolbar>
      </AppBar>
      <Modal
        isOpen={isOpen}
        isCentered={true}
        onClose={onCloseClick}
        closeOnOverlayClick={true}
        closeOnEsc={true}
      >
        <ModalOverlay>
          <ModalContent style={{ minWidth: "97%", minHeight: "95%", display: "flex", width: "100%" }}>
            <ModalBody style={{ padding: "0px", flex: "1", width: "100%" }} >
              <div style={{ height: "100%" }}>
                <div style={{ marginTop: "2em", marginRight: "2em", display: "flex", position: "absolute", right: 0, cursor: "pointer" }}>
                  <div className={menuClasses.changeImage} onClick={onCloseClick}
                    onMouseEnter={() => setHover(true)}
                    onMouseLeave={() => setHover(false)}>
                    <div className={menuClasses.backDiv}><span className={menuClasses.backBtn}>Close</span></div>
                    <img src={hover ? CloseDisable : CloseDefault} alt=""></img>
                  </div>
                </div>
                <NavigationPage />
              </div>
            </ModalBody>
          </ModalContent>
        </ModalOverlay>
      </Modal>
    </div >
  ));
};
export default React.memo(Header);
