import { observer } from "mobx-react-lite";
import React from "react";
import { makeStyles, Theme } from "@material-ui/core/styles";
import { IFormField } from "../../../../models/Ticket";
import MultipleImageField from "../../../../components/FormInputs/multipleImage/MultipleImageField";

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    color: '#585858'
  },
  title: {
    padding: '1em 0em',
    fontSize: '0.9575em',
    fontWeight: 600,
  },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  main: {
    display: 'flex',
    width: '50%',
    fontSize: '0.8575em',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    padding: '1em 0em'
  },
  label: {
    fontWeight: 600,
    opacity: 0.5
  },
  value: {
    marginLeft: '1em',
    textTransform: 'capitalize'
  },
  mainFullWidth: {
    display: 'flex',
    width: '100%',
    fontSize: '0.8575em',
    alignItems: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
    padding: '1em 0em'
  },
  valueFullWidth: {
    marginLeft: '1em',
    textTransform: 'capitalize',
    flexGrow: 1,
  },
}))

const GeneralForm = ({ fields }: { fields: IFormField[] }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <div className={classes.title}>General Information</div>
      <div className={classes.container}>
        {fields.map((field) => {
          return (
            <div className={field.type === "upload" ? classes.mainFullWidth : classes.main} key={field.id}>
              <span className={classes.label}>{field.name}:</span>
              <span className={field.type === "upload" ? classes.valueFullWidth : classes.value}>{field.type === 'dropdown' ? field.options.find(opt => opt.id === field.value)?.name || field.value || '-' :
                (field.type === 'upload' && Array.isArray(field.value)) ?
                  <MultipleImageField
                    field={field.id}
                    dispatch={() => { }}
                    state={field.value || []}
                    type={field.type}
                    disabled={true}
                    required={false}
                  />
                  :
                  field.value || '-'}</span>
            </div>
          )
        })}
      </div>
    </div>
  )
}

export default observer(GeneralForm)
